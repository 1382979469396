import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import { Box, Text } from 'grommet'
import { navigate, Link } from 'gatsby'
import { isEmpty, isEqual } from 'lodash/fp'
import styled from 'styled-components'

import Button from './Button'
import ColumnStack from './ColumnStack'
import ContentContainer from './ContentContainer'
import ResponsiveHidden from './ResponsiveHidden'
import FormField from './FormField'
import FieldSet from './FieldSet'
import FormInputS3File from './FormInputS3File'
import FormInputCheckBox from './FormInputCheckBox'
import ResponsiveStyler from './ResponsiveStyler'
import orderSetProductArt from '../store/actions/orderSetProductArt'
import { ChevronUp, ChevronDown } from './Icons'

const StyledForm = styled(Form)`
  height: 100%;
`

const NoArtText = () => (
  <Box pad={{ vertical: 'small' }}>
    <Text size="small">
      No worries! We’ll give you a call after you complete your order and we can
      design it together - for free.
    </Text>
  </Box>
)

const EnvelopeSchema = Yup.object().shape({
  artFront: Yup.string().when('noArt', {
    is: true,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Required'),
  }),
  noArt: Yup.boolean(),
})

class FormEnvelope extends React.PureComponent {
  componentDidMount() {
    const { productSku, product } = this.props
    if (isEmpty(productSku)) {
      return navigate(`/${product.toLowerCase()}`)
    }
  }
  render() {
    const { product, initialValues, orderSetProductArt } = this.props
    const isLetter = isEqual(product, 'letters')
    return (
      <Formik
        initialValues={initialValues}
        isInitialValid={() => EnvelopeSchema.isValidSync(initialValues)}
        validationSchema={EnvelopeSchema}
        onSubmit={async values => {
          const { artFront, noArt } = values
          await orderSetProductArt({
            envelope: artFront,
            noEnvelope: noArt,
          })
          navigate(`/${product.toLowerCase()}/list`)
        }}
        render={({ values, isValid }) => (
          <StyledForm>
            <ColumnStack fill gap="medium">
              <Box flex>
                <Box flex="grow" justify="center">
                  <ResponsiveHidden
                    hideAt={['medium', 'middle', 'large', 'xlarge']}
                  >
                    <Box>
                      <Link to={`/${product}/design`}>
                        <Button
                          label="Previous"
                          icon={<ChevronUp size="small" color="dark-1" />}
                        />
                      </Link>
                    </Box>
                  </ResponsiveHidden>
                  <ContentContainer side="left">
                    <ResponsiveStyler
                      positionAt={['medium', 'large']}
                      styleBlock={`z-index: 2; width: 100%; flex-grow: 1;`}
                      justify="center"
                    >
                      <Field
                        component={FormInputS3File}
                        product={product}
                        label={'Envelope Front'}
                        name="artFront"
                        minHeight={'180px'}
                        maxWidth="414px"
                        filename={values['artFront-filename']}
                        accept={[
                          'image/*',
                          'application/pdf',
                          'application/x-pdf',
                        ]}
                      />
                    </ResponsiveStyler>
                  </ContentContainer>
                </Box>
                <ResponsiveHidden>
                  <Box>
                    <Link to={`/${product}`}>
                      <Button
                        label="Previous"
                        icon={<ChevronUp size="small" color="dark-1" />}
                      />
                    </Link>
                  </Box>
                </ResponsiveHidden>
              </Box>
              <Box flex>
                <ContentContainer justify="center">
                  <FieldSet>
                    <Text color="dark-2" size="small">
                      Upload Design
                    </Text>
                    {!(values.artFront || values.artBack) && (
                      <FormField
                        label="I don't have a design"
                        extra={values.noArt && <NoArtText />}
                      >
                        <Field component={FormInputCheckBox} name="noArt" />
                      </FormField>
                    )}
                  </FieldSet>
                </ContentContainer>
                <Box margin={{ top: 'medium' }}>
                  <Button
                    primary
                    type="submit"
                    alignSelf="end"
                    disabled={!isValid}
                    reverse
                    label="Next"
                    icon={<ChevronDown size="small" />}
                  />
                </Box>
              </Box>
            </ColumnStack>
          </StyledForm>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  initialValues: {
    artFront: state.order.art.artFront,
    artBack: state.order.art.artBack,
    noArt: state.order.art.noEnvelope,
  },
  productSku: state.order.productSku,
})

export default connect(
  mapStateToProps,
  { orderSetProductArt }
)(FormEnvelope)
