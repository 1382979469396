import React from 'react'
import { Box } from 'grommet'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FormEnvelope from '../../components/FormEnvelope'

const LettersDesign = () => (
  <Layout productNav="letters">
    <SEO title="Letters - Design" />
    <Box pad={{ vertical: 'medium' }} fill>
      <FormEnvelope product="letters" />
    </Box>
  </Layout>
)

export default LettersDesign
